import { darkGenerator } from "./generators/dark-generator";
import { lightGenerator } from "./generators/light-generator";
import { mediumGenerator } from "./generators/medium-generator";
import { softGenerator } from "./generators/soft-generator";

const yellowLight = lightGenerator('yellow');
const yellowMedium = mediumGenerator('yellow');
const yellowSoft = softGenerator('yellow');
const yellowDark = darkGenerator('yellow');

export const yellowTheme = {
  yellow: {
    light: {
      ...yellowLight,
      color: {
        ...yellowLight.color,
        badgeBackground: 'bg-yellow-200',
      },
      button: {
        ...yellowLight.button,
        primary: {
          ...yellowLight.button?.primary,
          bgDefault: 'bg-yellow-500 group-hover:bg-yellow-600',
          textDefault: 'text-yellow-1000',
          outlineHover: 'border-yellow-800',
          outlineHoverBackground: 'group-hover:bg-yellow-100',
          outlineHoverText: 'hover:text-yellow-800',
          outlineActive: 'border-yellow-800',
        }
      }
    },
    soft: {
      ...yellowSoft,
      button: {
        ...yellowSoft.button,
        primary: {
          ...yellowSoft.button?.primary,
          bgDefault: 'bg-yellow-500',
          bgHover: 'group-hover:bg-yellow-600',
          textDefault: 'text-yellow-1000',
          outlineHoverText: 'hover:text-yellow-700',
          outlineHoverBackground: 'group-hover:bg-yellow-50',
        }
      }
    },
    medium: {
      ...yellowMedium,
      background: 'bg-yellow-500',
      color: {
        ...yellowMedium.color,
        badgeBackground: 'bg-yellow-200',
        heading: 'text-yellow-1000',
        headingSecondary: 'text-yellow-800',
        text: 'text-yellow-1000',
      },
      button: {
        ...yellowMedium.button,
        primary: {
          ...yellowMedium.button?.primary,
          bgDefault: 'bg-yellow-0',
          bgHover: 'group-hover:bg-yellow-50',
          bgActive: 'active:bg-yellow-50',
          outlineDefault: 'border border-yellow-1000',
          outlineHoverText: 'hover:text-yellow-700',
          outlineHover: 'hover:border-yellow-1000',
          outlineHoverBackground: 'group-hover:bg-yellow-50',
          outlineActiveBackground: 'active:bg-yellow-50',
          outlineActive: 'border-yellow-1000',
          textOutline: 'text-yellow-1000',
        }
      },
      input: {
        ...yellowDark.input,
        placeholderColor: 'placeholder-yellow-1000',
        border: 'border-yellow-1000',
      },
    },
    dark: {
      ...yellowDark,
      button: {
        ...yellowDark.button,
        primary: {
          ...yellowDark.button?.primary,
          bgDefault: 'bg-yellow-300',
          bgHover: 'group-hover:bg-yellow-400',
          bgActive: 'active:bg-yellow-400',
          textDefault: 'text-yellow-1000',
          outlineDefault: 'border border-yellow-300',
          outlineHover: 'border-yellow-400',
          outlineHoverBackground: 'group-hover:bg-yellow-50',
          outlineActiveBackground: 'active:bg-yellow-50',
          outlineActive: 'border-yellow-400',
          textOutline: 'text-yellow-300',
        },
        secondary: {
          ...yellowDark.button?.secondary,
          bgDefault: 'bg-yellow-100',
          bgHover: 'group-hover:bg-yellow-200',
          bgActive: 'active:bg-yellow-200',
          textDefault: 'text-yellow-1000',
          outlineDefault: 'border border-yellow-100',
          outlineHover: 'group-hover:border-yellow-200',
          outlineActive: 'border-yellow-200',
          outlineHoverBackground: 'group-hover:bg-yellow-900',
          outlineActiveBackground: 'active:bg-yellow-900',
          outlineHoverText: 'group-hover:text-yellow-0',
        }
      },
      input: {
        ...yellowDark.input,
        placeholderColor: 'placeholder-yellow-100',
        border: 'border-yellow-50',
      },
    },
  }
};

export const BACKGROUNDS = {
  light: 'bg-white',
  dark: 'bg-indigo-800',

  lightBlue: 'bg-blue-0',
  mediumLightBlue: 'bg-blue-100',
  lighterBlue: 'bg-blue-300',
  mediumBlue: 'bg-blue-400',
  mediumDarkBlue: 'bg-blue-500',
  darkBlue: 'bg-blue-1000',
  blueBackgroundDefault: 'bg-blue-700',
  blueBackgroundHover: 'bg-blue-800',
  blueBackgroundActive: 'bg-blue-800',
  blueBackgroundSecondary: 'bg-blue-900',
  blueBackgroundSecondaryHover: 'bg-blue-950',
  
  lightGreen: 'bg-green-0',
  mediumLightGreen: 'bg-green-100',
  lighterGreen: 'bg-green-300',
  mediumGreen: 'bg-green-400',
  mediumDarkGreen: 'bg-green-800',
  darkGreen: 'bg-green-1000',
  greenBackgroundDefault: 'bg-green-700',
  greenBackgroundHover: 'bg-green-800',
  greenBackgroundActive: 'bg-green-800',


  lightYellow: 'bg-yellow-0',
  mediumLightYellow: 'bg-yellow-100',
  lighterYellow: 'bg-yellow-300',
  mediumYellow: 'bg-yellow-400',
  mediumDarkYellow: 'bg-yellow-500',
  darkYellow: 'bg-yellow-1000',
  yellowBackgroundDefault: 'bg-yellow-700',
  yellowBackgroundHover: 'bg-yellow-800',
  yellowBackgroundActive: 'bg-yellow-800',

  lightNavy: 'bg-navy-0',
  mediumLightNavy: 'bg-navy-100',
  lighterNavy: 'bg-navy-300',
  mediumNavy: 'bg-navy-400',
  mediumDarkNavy: 'bg-navy-600',
  darkNavy: 'bg-navy-1000',
  navyBackgroundDefault: 'bg-navy-700',
  navyBackgroundHover: 'bg-navy-800',
  navyBackgroundActive: 'bg-navy-800',

  // check if we need these colors
  blueLight: 'bg-blue-0',
  blueSoft: 'bg-blue-100',
  blueMedium: 'bg-blue-500',
  blueDark: 'bg-blue-1000',

  greenLight: 'bg-green-0',
  greenSoft: 'bg-green-100',
  greenMedium: 'bg-green-800',
  greenDark: 'bg-green-1000',

  yellowLight: 'bg-yellow-0',
  yellowSoft: 'bg-yellow-100',
  yellowMedium: 'bg-yellow-500',
  yellowDark: 'bg-yellow-1000',

  navyLight: 'bg-navy-0',
  navySoft: 'bg-navy-100',
  navyMedium: 'bg-navy-600',
  navyDark: 'bg-navy-1000',
} as const;

type ObjectValues<T> = T[keyof T];
export type BackgroundType = ObjectValues<typeof BACKGROUNDS>;
'use client';
import { BREAKPOINTS } from '@deardigital/shared/constants';
import { useEffect, useState } from 'react';

/**
 * Determines whether the current viewport is in mobile mode.
 * @returns {boolean} True if the viewport is in mobile mode, false otherwise.
 */
export const isInMobileBreakpointView = (): boolean => {
  const [isInMobileView, setIsInMobileView] = useState(false);

  const updateViewport = (): void => {
    setIsInMobileView(window.innerWidth < BREAKPOINTS.lg);
  };

  useEffect(() => {
    window.addEventListener('resize', updateViewport);
    updateViewport(); // Initial check

    return () => window.removeEventListener('resize', updateViewport);
  }, []);

  return isInMobileView;
};

import { darkGenerator } from './generators/dark-generator';
import { lightGenerator } from './generators/light-generator';
import { mediumGenerator } from './generators/medium-generator';
import { softGenerator } from './generators/soft-generator';

export const violetTheme = {
  violet: {
    light: {
      ...lightGenerator('violet'),
    },
    soft: {
      ...softGenerator('violet'),
    },
    medium: {
      ...mediumGenerator('violet'),
    },
    dark: {
      ...darkGenerator('violet'),
    },
  }
}

export const COLORS = {
  light: 'text-white',
  blue800: 'text-indigo-800',
  blue500: 'text-indigo-500',
  navy500: 'text-blue-800',
  gray600: 'text-gray-600',

  blue0: 'text-blue-0',
  blue50: 'text-blue-50',
  blue200: 'text-blue-200',
  blue300: 'text-blue-300',
  blue400: 'text-blue-400',
  blue700: 'text-blue-700',
  blue900: 'text-blue-900',
  blue1000: 'text-blue-1000',

  green0: 'text-green-0',
  green50: 'text-green-50',
  green100: 'text-green-100',
  green200: 'text-green-200',
  green300: 'text-green-300',
  green400: 'text-green-400',
  green700: 'text-green-700',
  green800: 'text-green-800',
  green900: 'text-green-900',
  green950: 'text-green-950',
  green1000: 'text-green-1000',

  yellow0: 'text-yellow-0',
  yellow50: 'text-yellow-50',
  yellow100: 'text-yellow-100',
  yellow200: 'text-yellow-200',
  yellow300: 'text-yellow-300',
  yellow400: 'text-yellow-400',
  yellow700: 'text-yellow-700',
  yellow800: 'text-yellow-800',
  yellow900: 'text-yellow-900',
  yellow950: 'text-yellow-950',
  yellow1000: 'text-yellow-1000',

  navy0: 'text-navy-0',
  navy50: 'text-navy-50',
  navy100: 'text-navy-100',
  navy200: 'text-navy-200',
  navy300: 'text-navy-300',
  navy400: 'text-navy-400',
  navy700: 'text-navy-700',
  navy800: 'text-navy-800',
  navy900: 'text-navy-900',
  navy950: 'text-navy-950',
  navy1000: 'text-navy-1000',
} as const;

type ObjectValues<T> = T[keyof T];
export type ColorType = ObjectValues<typeof COLORS>;

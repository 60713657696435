import { darkGenerator } from './generators/dark-generator';
import { lightGenerator } from './generators/light-generator';
import { mediumGenerator } from './generators/medium-generator';
import { softGenerator } from './generators/soft-generator';

export const indigoTheme = {
  indigo: {
    light: {
      ...lightGenerator('indigo'),
    },
    soft: {
      ...softGenerator('indigo'),
    },
    medium: {
      ...mediumGenerator('indigo'),
    },
    dark: {
      ...darkGenerator('indigo'),
    },
  }
}

import { darkGenerator } from './generators/dark-generator';
import { lightGenerator } from './generators/light-generator';
import { mediumGenerator } from './generators/medium-generator';
import { softGenerator } from './generators/soft-generator';

export const skyTheme = {
  sky: {
    light: {
      ...lightGenerator('sky'),
    },
    soft: {
      ...softGenerator('sky'),
    },
    medium: {
      ...mediumGenerator('sky'),
    },
    dark: {
      ...darkGenerator('sky'),
    },
  }
}

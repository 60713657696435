import { BACKGROUNDS } from "../background";
import { COLORS } from "../color";
import { EDITORS } from "../editor";

export const defaultTheme = {
  default: {
    light: {
      editor: EDITORS.light,
      background: BACKGROUNDS.light,
      color: {
        badgeBackground: `bg-blue-100`,
        heading: COLORS.blue800,
        headingSecondary: `text-blue-700`,
        text: COLORS.blue500,
        badgeText: `text-blue-950`,
        timeline: BACKGROUNDS.dark,
        timelineText: COLORS.blue500,
        cta: `text-blue-900`,
      },
      input: {
        placeholderColor: 'placeholder-indigo-800',
        border: 'border-indigo-800',
      },
      button: {
        primary: {
          bgDefault: 'bg-blue-700',
          bgHover: 'group-hover:bg-blue-800',
          bgActive: 'active:bg-blue-800',
          textDefault: 'text-blue-0',
          outlineDefault: 'border-blue-700',
          outlineHover: 'border-blue-800',
          outlineHoverBackground: `hover:bg-blue-50`,
          outlineActiveBackground: `active:bg-blue-50`,
          outlineActive: 'border-blue-800',
          textOutline: 'text-blue-700',
        },
        secondary: {
          bgDefault: 'bg-blue-900',
          bgHover: 'group-hover:bg-blue-950',
          bgActive: 'active:bg-blue-950',
          textDefault: 'text-blue-0',
          outlineDefault: `border-blue-900`,
          outlineHover: `hover:border-blue-950`,
          outlineHoverBackground: `hover:bg-blue-950`,
          outlineActiveBackground: `active:bg-blue-900`,
          outlineHoverText: `hover:text-blue-0`,
          outlineActive: `border-blue-950`,
          textOutline: `text-blue-900`,
          },
        textLink: {
          textColor: 'text-blue-900',
        },
      },
    },
    dark: {
      editor: EDITORS.dark,
      background: BACKGROUNDS.dark,
      color: {
        heading: COLORS.light,
        text: COLORS.light,
        timeline: BACKGROUNDS.light,
        timelineText: COLORS.navy500,
      },
      input: {
        placeholderColor: 'placeholder-white',
        border: 'border-white',
      },
      button: {
        primary: {
          bgDefault: 'bg-blue-700',
          bgHover: 'group-hover:bg-blue-800',
          bgActive: 'active:bg-blue-800',
          textDefault: 'text-blue-0',
          outlineDefault: 'border-blue-700',
          outlineHover: 'border-blue-800',
          outlineActive: 'border-blue-800',
          textOutline: 'text-blue-700',
        },
        secondary: {
          bgDefault: 'bg-blue-900',
          bgHover: 'group-hover:bg-blue-950',
          bgActive: 'active:bg-blue-950',
          textDefault: 'text-blue-0',
          outlineDefault: 'border-blue-900',
          outlineHover: 'border-blue-950',
          outlineActive: 'border-blue-950',
          textOutline: 'text-blue-900',
        },
        textLink: {
          textColor: 'text-blue-100',
        },
      },
    },
  }
}

import { darkGenerator } from "./generators/dark-generator";
import { lightGenerator } from "./generators/light-generator";
import { mediumGenerator } from "./generators/medium-generator";
import { softGenerator } from "./generators/soft-generator";

const navyLight = lightGenerator('navy');
const navyMedium = mediumGenerator('navy');
const navySoft = softGenerator('navy');
const navyDark = darkGenerator('navy');

export const navyTheme = {
  navy: {
    light: {
      ...navyLight,
      button: {
        ...navyLight.button,
        primary: {
          ...navyLight.button?.primary,
          outlineHover: 'border-navy-800',
          outlineActive: 'border-navy-800',
        }
      }
    },
    soft: {
      ...navySoft,
    },
    medium: {
      ...navyMedium,
      background: 'bg-navy-600',
    },
    dark: {
      ...navyDark,
    },
  }
};

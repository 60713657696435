export const TITLE_SIZE = {
  xs: 'text-sm sm:text-base',
  sm: 'text-xl lg:text-2xl',
  bs: 'text-2xl sm:text-3xl lg:text-4xl',
  md: 'text-3xl sm:text-4xl lg:text-5xl',
  lg: 'text-4xl sm:text-6xl lg:text-8xl',
  xl: 'text-6xl sm:text-8xl lg:text-9xl',
} as const;

type ObjectValues<T> = T[keyof T];
export type TitleSizeType = ObjectValues<typeof TITLE_SIZE>;

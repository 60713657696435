import { darkGenerator } from "./generators/dark-generator";
import { lightGenerator } from "./generators/light-generator";
import { mediumGenerator } from "./generators/medium-generator";
import { softGenerator } from "./generators/soft-generator";

const greenLight = lightGenerator('green');
const greenMedium = mediumGenerator('green');
const greenSoft = softGenerator('green');
const greenDark = darkGenerator('green');

export const greenTheme = {
  green: {
    light: {
      ...greenLight,
      button: {
        ...greenLight.button,
        primary: {
          ...greenLight.button?.primary,
          outlineHover: 'border-green-800',
          outlineActive: 'border-green-800',
        }
      }
    },
    soft: {
      ...greenSoft,
    },
    medium: {
      ...greenMedium,
      background: 'bg-green-800',
    },
    dark: {
      ...greenDark,
      button: {
        ...greenDark.button,
        secondary: {
          ...greenDark.button?.secondary,
          bgDefault: 'bg-green-0',
        }
      }
    },
  }
};

import { darkGenerator } from './generators/dark-generator';
import { lightGenerator } from './generators/light-generator';
import { mediumGenerator } from './generators/medium-generator';
import { softGenerator } from './generators/soft-generator';

export const redTheme = {
  red: {
    light: {
      ...lightGenerator('red'),
    },
    soft: {
      ...softGenerator('red'),
    },
    medium: {
      ...mediumGenerator('red'),
    },
    dark: {
      ...darkGenerator('red'),
    },
  }
}

export const RATIOS = {
  // mobile ratios
  ratio_1x1: 'aspect-square',
  ratio_2x1: 'aspect-2/1',
  ratio_16x9: 'aspect-video',
  ratio_21x9: 'aspect-21/9',
  ratio_9x16: 'aspect-9/16',
  ratio_4x3: 'aspect-4/3',
  ratio_3x4: 'aspect-3/4',
  DEFAULT: 'aspect-square',
  NONE: '',

  // desktop ratios
  lg_ratio_1x1: 'lg:aspect-square',
  lg_ratio_2x1: 'lg:aspect-2/1',
  lg_ratio_16x9: 'lg:aspect-video',
  lg_ratio_21x9: 'lg:aspect-21/9',
  lg_ratio_9x16: 'lg:aspect-9/16',
  lg_ratio_4x3: 'lg:aspect-4/3',
  lg_ratio_3x4: 'lg:aspect-3/4',
} as const;

type ObjectValues<T> = T[keyof T];
export type RatioType = ObjectValues<typeof RATIOS>;

export const EDITORS = {
  light: 'editor',
  dark: 'editor editor-dark',
  
  blueLight: 'editor editor-light-blue',
  blueSoft: 'editor editor-light-medium-blue',
  blueMedium: 'editor editor-medium-dark-blue',
  blueDark: 'editor editor-dark-blue',

  greenLight: 'editor editor-light-green',
  greenSoft: 'editor editor-light-medium-green',
  greenMedium: 'editor editor-medium-dark-green',
  greenDark: 'editor editor-dark-green',

  yellowLight: 'editor editor-light-yellow',
  yellowSoft: 'editor editor-light-medium-yellow',
  yellowMedium: 'editor editor-medium-dark-yellow',
  yellowDark: 'editor editor-dark-yellow',

  navyLight: 'editor editor-light-navy',
  navySoft: 'editor editor-light-medium-navy',
  navyMedium: 'editor editor-medium-dark-navy',
  navyDark: 'editor editor-dark-navy',
} as const;

type ObjectValues<T> = T[keyof T];
export type EditorType = ObjectValues<typeof EDITORS>;

import { redTheme } from './themes/red';
import { blueTheme } from './themes/blue';
import { greenTheme } from './themes/green';
import { navyTheme } from './themes/navy';
import { yellowTheme } from './themes/yellow';
import { emeraldTheme } from './themes/emeral';
import { indigoTheme } from './themes/indigo';
import { skyTheme } from './themes/sky';
import { violetTheme } from './themes/violet';
import { defaultTheme } from './themes/default';

export const COLOR_THEMES = {
  ...defaultTheme,
  ...blueTheme,
  ...greenTheme,
  ...yellowTheme,
  ...navyTheme,
  ...redTheme,
  ...emeraldTheme,
  ...skyTheme,
  ...indigoTheme,
  ...violetTheme,
} as const;

type ObjectValues<T> = T[keyof T];
export type ColorThemeType = ObjectValues<typeof COLOR_THEMES>;

export const VerticalContentAlignmentConstant = {
    start: 'items-start',
    center: 'items-center',
    end: 'items-end',
  } as const;
  
  type ObjectValues<T> = T[keyof T];
  export type VerticalContentAlignmentType = ObjectValues<typeof VerticalContentAlignmentConstant>;
  
  export const HorizontalContentAlignmentConstant = {
    start: 'justify-start',
    center: 'justify-center',
    end: 'justify-end',
  } as const;
   
  export type HorizontalContentAlignmentType = ObjectValues<typeof HorizontalContentAlignmentConstant>;
  
  export const TextContentAlignmentConstant = {
    left: 'text-left justify-start',
    center: 'text-center justify-center',
    right: 'text-right justify-end',
  } as const;
  export type TextContentAlignmentType = ObjectValues<typeof TextContentAlignmentConstant>;
  
  export const ContentContentAlignmentConstant = {
    left: '',
    center: 'mx-auto',
  } as const;
  
  export type ContentContentAlignmentType = ObjectValues<typeof ContentContentAlignmentConstant>;
  
import { darkGenerator } from './generators/dark-generator';
import { lightGenerator } from './generators/light-generator';
import { mediumGenerator } from './generators/medium-generator';
import { softGenerator } from './generators/soft-generator';

export const emeraldTheme = {
  emerald: {
    light: {
      ...lightGenerator('emerald'),
    },
    soft: {
      ...softGenerator('emerald'),
    },
    medium: {
      ...mediumGenerator('emerald'),
    },
    dark: {
      ...darkGenerator('emerald'),
    },
  }
}

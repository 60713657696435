export const VerticalAlignmentConstant = {
  start: 'items-start',
  center: 'items-center',
  end: 'items-end',
} as const;

type ObjectValues<T> = T[keyof T];
export type VerticalAlignmentType = ObjectValues<typeof VerticalAlignmentConstant>;

export const TextAlignmentConstant = {
  left: 'text-left',
  center: 'text-center',
} as const;
export type TextAlignmentType = ObjectValues<typeof TextAlignmentConstant>;

export const HorizontalAlignmentConstant = {
  left: '',
  center: 'lg:col-start-2',
} as const;

export type HorizontalAlignmentType = ObjectValues<typeof HorizontalAlignmentConstant>;

export const ContentAlignmentConstant = {
  left: '',
  center: 'mx-auto text-center',
} as const;

export type ContentAlignmentType = ObjectValues<typeof ContentAlignmentConstant>;

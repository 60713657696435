export const PAGE_TYPES = {
  blog: 'blog/',
  challenge: 'challenge/',
  client: 'client/',
  demo: 'demo/',
  home: 'home',
  page: 'page/',
  podcast: 'podcast/',
  products: 'products/',
  root: '',
  services: 'services/',
  shop: 'shop/',
  team: 'team/',
  technology: 'technology/',
  test: 'test/',
  whitepaper: 'whitepaper/',
  work: 'work/',
} as const;

type ObjectValues<T> = T[keyof T];
export type PageTypeType = ObjectValues<typeof PAGE_TYPES>;

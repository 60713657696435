import { darkGenerator } from './generators/dark-generator';
import { lightGenerator } from './generators/light-generator';
import { mediumGenerator } from './generators/medium-generator';
import { softGenerator } from './generators/soft-generator';

export const blueTheme = {
  blue: {
    light: {
      ...lightGenerator('blue'),
    },
    soft: {
      ...softGenerator('blue'),
    },
    medium: {
      ...mediumGenerator('blue'),
    },
    dark: {
      ...darkGenerator('blue'),
    },
  }
}

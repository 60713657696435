export const LINK_STYLES = {
    indigoFilter: "indigo-filter",
    indigoFilterOutline: "indigo-filter-outline",
    textIndigo: "text-indigo",
    textWhite: "text-white",
    blue: "blue",
    indigoOutline: "indigo-outline",
    whiteOutline: "white-outline",
    blueLight: 'blue-light',
    primary: 'primary',
    primaryOutline: 'primary-outline',
    secondary: 'secondary',
    secondaryOutline: 'secondary-outline',
    textLink: 'text-link',
} as const;

type ObjectValues<T> = T[keyof T];
export type LinkStyleType = ObjectValues<typeof LINK_STYLES>

export const ICONS = {
  arrowLeft: 'arrow-left',
  arrowRight: 'arrow-right',
  plusIcon: 'plus-icon',
  plusIconSmall: 'plus-icon-small',
  closeIcon: 'close-icon',
  navbarToggle: 'navbar-toggle',
} as const;

type ObjectValues<T> = T[keyof T];
export type IconType = ObjectValues<typeof ICONS>;

export const SPACINGS = {
  sm: 'py-5 md:py-10',
  md: 'py-10 md:py-20',
  lg: 'py-20 md:py-40',
  xl: 'py-30 md:py-60',
  smnone: 'pt-5 md:pt-10',
  mdnone: 'pt-10 md:pt-20',
  lgnone: 'pt-20 md:pt-40',
  lgsm: 'pt-20 md:pt-40 pb-5 md:pb-8',
  xlnone: 'pt-30 md:pt-60',
  nonesm: 'pb-5 md:pb-10',
  nonemd: 'pb-10 md:pb-20',
  nonelg: 'pb-20 md:pb-40',
  nonexl: 'pb-30 md:pb-60',
  mdsm: 'pt-12 pb-5 md:pt-20 md:pb-8',
  badge: 'pb-4',
  none: 'p-0',
};

type ObjectValues<T> = T[keyof T];
export type SpacingType = ObjectValues<typeof SPACINGS>;

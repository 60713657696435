import { ColorShade } from '../interface/color-shade';

export function mediumGenerator(color: string): ColorShade {
  return {
    name: `${color}-medium`,
    editor: `editor editor-${color}-medium`,
    background: `bg-${color}-700`,
    spacing: 'py-10 md:py-20',
    color: {
      badgeBackground: `bg-${color}-100`,
      cardBackground: `bg-${color}-300`,
      heading: `text-${color}-0`,
      headingSecondary: `text-${color}-200`,
      text: `text-${color}-0`,
      textHover: `hover:text-${color}-200`,
      badgeText: `text-${color}-950`,
      timeline: `after:bg-${color}-300 before:bg-${color}-300`,
      timelineText: `text-${color}-100`,
      cta: `text-${color}-950`,
      divide: `divide-${color}-0`,
      inputBorder: `border-${color}-500`,
    },
    button: {
      primary: {
        bgDefault: `bg-${color}-300`,
        bgHover: `group-hover:bg-${color}-400`,
        bgActive: `active:bg-${color}-400`,
        textDefault: `text-${color}-1000`,
        outlineDefault: `border border-${color}-300`,
        outlineHover: `hover:border-${color}-300`,
        outlineHoverBackground: `hover:bg-${color}-50`,
        outlineActiveBackground: `active:bg-${color}-50`,
        outlineActive: `border-${color}-300`,
        outlineHoverText: `hover:text-${color}-300`,
        textOutline: `text-${color}-50`,
      },
      secondary: {
        bgDefault: `bg-${color}-50`,
        bgHover: `group-hover:bg-${color}-100`,
        bgActive: `active:bg-${color}-100`,
        textDefault: `text-${color}-950`,
        outlineDefault: `border border-${color}-50`,
        outlineHover: `hover:border-${color}-100`,
        outlineActive: `border-${color}-100`,
        outlineHoverBackground: `hover:bg-${color}-100`,
        outlineActiveBackground: `active:bg-${color}-100`,
        outlineHoverText: `hover:text-${color}-950`,
        textOutline: `text-${color}-50`,
      },
      textLink: {
        textColor: `text-${color}-100`,
      },
    },
    input: {
      placeholderColor: `placeholder-${color}-100`,
      border: `border-${color}-100`,
    },
  }
}
